
import { defineComponent, reactive, ref } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getTargetDevice } from "@/core/services/api/device";
import { useRoute, useRouter } from "vue-router";
import {
  deleteDeviceMapping,
  getDeviceMapping,
} from "@/core/services/api/deviceMapping";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getFacilityUnitByFacilities } from "@/core/services/api/facilityUnits";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString } from "@/core/utils/timeString";
import AddIoTMapping from "./components/AddIoTMapping.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    AddIoTMapping,
  },
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const route = useRoute();
    const router = useRouter();
    const deviceGuid = route.params.deviceGuid;
    const device: any = reactive({});
    const mappingList: any = reactive([]);
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "目標類型",
        key: "targetType",
        sortable: true,
      },
      {
        name: "目標",
        key: "target",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const targetTypeMap = {
      Facility: "設施",
      FacilityUnit: "座位",
    };
    const facilities: any = reactive([]);
    const facilityUnits: any = reactive([]);
    const addMappingVisible = ref(false);
    const currentItem: any = reactive({});

    const setDevice = async () => {
      let request = await getTargetDevice(deviceGuid);
      Object.assign(device, request);
    };

    const setDeviceMapping = async () => {
      let request = await getDeviceMapping(deviceGuid);
      mappingList.splice(0, mappingList.length, ...request);
      
    };

    const getTarget = (targetType, guid) => {
      if (targetType == "Facility") {
        return facilities.filter((o) => o.facility.guid == guid)[0].facility;
      } else if (targetType == "FacilityUnit") {
        return facilityUnits.filter((o) => o.guid == guid)[0];
      }
    };

    const setTableData = () => {
      tableData.splice(0);
      for (const item of mappingList) {
        tableData.push({
          data: item,
          targetType: targetTypeMap[item.targetType],
          target: getTarget(item.targetType, item.target).name,
          createTime: getLocalTimeString(item.createTime),
        });
      }
    };

    const setFacilities = async () => {
      let response = await getFacilityBySpace(currentSpaceGuid!);
      facilities.splice(0, facilities.length, ...response);
      console.log("facilities", facilities);
    };

    const setFacilityUnits = async () => {
      let facilitiesGuid = facilities.map((o) => o.facility.guid);
      let request = { facilities: facilitiesGuid };
      let response = await getFacilityUnitByFacilities(request);
      facilityUnits.splice(0, facilityUnits.length, ...response);
    };

    const onAddIoTMapping = () => {
      addMappingVisible.value = false;
      init();
    };

    const setCurrentItem = (item) => {
      Object.assign(currentItem, item);
    };

    const deleteIoTMapping = async () => {
      await Swal.fire({
        title: `您確定要刪除與 "${currentItem.target}" 的對應嗎?`,
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async () => {
        await deleteDeviceMapping(deviceGuid, currentItem.data.guid).then(
          async () => {
            await init();
            Swal.fire("刪除成功!", "", "success");
          }
        );
      });
    };

    const init = async () => {
      await setDevice();
      await setDeviceMapping();
      await setFacilities();
      await setFacilityUnits();
      setTableData();
    };
    init();

    return {
      device,
      tableData,
      tableHeader,
      addMappingVisible,
      deviceGuid,
      onAddIoTMapping,
      deleteIoTMapping,
      setCurrentItem,
    };
  },
});
