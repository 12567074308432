<template>
  <Dialog append-to-body title="新增裝置情境" v-model="dialogShow" width="35%">
    <el-form
      label-position="left"
      :model="formData"
      :rules="rules"
      ref="formRef"
      class="form"
      label-width="110px"
    >
      <el-form-item label="目標類型" prop="targetType">
        <el-select v-model="formData.targetType" placeholder="請選擇目標類型">
          <el-option
            v-for="item in targetTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="目標" prop="target">
        <el-select
          v-if="formData.targetType == 'Facility'"
          v-model="formData.target"
          placeholder="請選擇目標"
        >
          <el-option
            v-for="item in facilitiesOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select
          v-if="formData.targetType == 'FacilityUnit'"
          v-model="formData.target"
          placeholder="請選擇目標"
        >
          <el-option
            v-for="item in facilityUnitOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="d-flex justify-content-end">
        <button @click="onSubmit()" type="button" class="btn btn-primary">
          新增
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script lang='ts'>
import { computed, defineComponent, reactive, ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addDeviceMapping } from "@/core/services/api/deviceMapping";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getFacilityUnitByFacilities } from "@/core/services/api/facilityUnits";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  components: {
    Dialog,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    deviceGuid: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const dialogShow = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      },
    });
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = reactive({
      targetType: "Facility",
      target: "",
    });
    const rules = {
      targetType: [
        { required: true, message: "請選擇目標類型", trigger: "change" },
      ],
      target: [{ required: true, message: "請選擇目標", trigger: "change" }],
    };
    const targetTypeOption: any = reactive([
      {
        label: "設施",
        value: "Facility",
      },
      {
        label: "座位",
        value: "FacilityUnit",
      },
    ]);
    const facilities: any = reactive([]);
    const facilitiesOption: any = reactive([]);
    const facilityUnitOption: any = reactive([]);

    const onSubmit = () => {
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                targetType: formData.targetType,
                target: formData.target,
              };
              await addDeviceMapping(props.deviceGuid, request).then(
                async () => {
                  await Swal.fire("新增成功!", "", "success");
                  emit("onAdd");
                }
              );
            }
          });
        }
      });
    };

    const setFacilitiesOption = async () => {
      let response = await getFacilityBySpace(currentSpaceGuid!);
      facilities.splice(0, facilities.length, ...response);
      facilitiesOption.splice(0);
      for (const item of response) {
        facilitiesOption.push({
          label: item.facility.name,
          value: item.facility.guid,
        });
      }
      console.log("facilitiesOption", facilitiesOption);
    };

    const setFacilityUnitOption = async () => {
      let facilitiesGuid = facilities.map((o) => o.facility.guid);
      let request = { facilities: facilitiesGuid };
      let response = await getFacilityUnitByFacilities(request);
      for (const item of response) {
        facilityUnitOption.push({
          label: item.name,
          value: item.guid,
        });
      }
    };

    const init = async () => {
      await setFacilitiesOption();
      await setFacilityUnitOption();
    };
    init();

    return {
      ...props,
      dialogShow,
      formData,
      rules,
      formRef,
        onSubmit,
      targetTypeOption,
      facilitiesOption,
      facilityUnitOption,
    };
  },
});
</script>

<style scoped>
</style>